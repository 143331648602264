
import rootReducer from '../reducers/reducer';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import en from '../intl/en'
import zhCn from '../intl/zh-cn'
import zhTw from '../intl/zh-tw'
import AsyncStorage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { persistStore, persistReducer } from 'redux-persist'
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

const translationsObject = {
    'en': en,
    'zh-tw': zhTw,
    'zh-cn': zhCn,
};


const persistConfig = {
    key: 'root10',
    storage: AsyncStorage,
    blacklist: [],//黑名单
    whitelist: ['config', 'user'],//白名单
}

const persistedReducer = persistReducer(persistConfig, rootReducer)



const store = configureStore(
    {
        reducer: persistedReducer,
        devTools: true,
        //无法序列化
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
                immutableCheck: false,
            }),
    }
);
store.dispatch(<any>loadTranslations(translationsObject));
store.dispatch(<any>setLocale('zh-tw'));
syncTranslationWithStore(store)
const persistor = persistStore(store)

export { store, persistor };


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;