import { useEffect, useState,memo } from "react";
import { PageBox, Nav } from "../../components";
import { color, size } from "../../theme";
import { useAppDispatch, useAppSelector } from "../../store/hooks.ts";
import { MyText } from "../../components/MyText";
import *as imgs from "../../imgs";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';


export type MiziCoinUseProps = {

}

type useDataType = {
    title: string
    contents: string[]
}[]

const useDataZhTw: useDataType = [
    {
        title: '米仔幣派發',
        contents: [
            '每位合資格會員每週可獲取10枚米仔幣, 發放時間為逢星期一凌晨00：00。'
        ]
    },
    {
        title: '米仔幣有效期',
        contents: [
            '每週的米仔幣有效期為該週的周一凌晨00：00至周日晚上23：59。',
            '每週的米仔幣不能累積，今週未使用的米仔幣會於星期日晚上23：59過期， 而星期一00：00會發放新一個星期的米仔幣; 而可用時段列明於米仔幣頁面。',
        ]
    },
    {
        title: '顯示可用的米仔幣數量',
        contents: [
            '手機程式內的會員頁面會顯示以家庭人數作單位的米仔幣總和， 例如4人家庭將顯示 （10 枚米仔幣 x 4 =）40 枚米仔幣。',
        ]
    },
    {
        title: '米仔站會員換領區',
        contents: [
            '於米仔站內的會員換領區，將標明換領各類貨品所需的米仔幣。',
            '米仔站內貨品皆為捐贈貨品，並會不定時更換款式，數量有限，換完即止。',
            '部份貨品換領將有限額，必須按店內守則換領。',
            '請自備環保袋, 店內不會提供膠袋或紙袋。',
        ]
    },
    {
        title: '如何用米仔幣換領貨品',
        contents: [
            '會員可按自己需要去揀選合適的貨品，並須攜往服務處由店員協助換領。',
            '會員須在店員面前打開手機程式上的會員二維碼給店員掃描，店員會按會員所揀選貨品類別扣減米仔幣以作換領。',
            '由於米仔幣是按家庭人數發放總數量，會員於換領貨品時，均以家庭戶口總數內扣減所需的米仔幣。',
        ]
    },
    {
        title: '米仔站注意事項',
        contents: [
            '請遵守站內規則及秩序，若店內人數眾多，將會實施人流管制措施，屆時必須遵從店員指示進入會員換領區換領貨品。 ',
            '換領貨品時，必須出示手機程式上的會員二維碼，不接受截圖。只接受會員本人或所屬家庭成員換領， 賬號切勿外傳，否則，惜食堂保留暫停服務的權利。',
            '換領貨品前，請小心檢查包裝及食用期，一經確認換領，不設退換。 ',
            '購物區物品不能以米仔幣換領。 ',
            '店內已安裝閉路電視及錄影系統，作保安之用。 ',
        ]
    },
]


const useDataZhCn: useDataType = [
    {
        title: '米仔币派发',
        contents: [
            '每位合资格会员每周可获取10枚米仔币,发放时间为逢星期一凌晨00:00。'
        ]
    },
    {
        title: '米仔币有效期',
        contents: [
            '每周的米仔币有效期为该周的周一凌晨00:00至周日晚上23:59。',
            '每周的米仔币不能累积,今周未使用的米仔币会于星期日晚上23:59过期,而星期一00:00会发放新一个星期的米仔币；而可用时段列明于米仔币页面。',
        ]
    },
    {
        title: '显示可用的米仔币数量',
        contents: [
            '手机程序内的会员页面会显示以家庭人数作单位的米仔币总和,例如4人家庭将显示（10枚米仔币x 4 =）40枚米仔币。',
        ]
    },
    {
        title: '米仔站会员换领区',
        contents: [
            '于米仔站内的会员换领区,将标明换领各类货品所需的米仔币。',
            '米仔站内货品皆为捐赠货品,并会不定时更换款式,数量有限,换完即止。',
            '部份货品换领将有限额,必须按店内守则换领。',
            '请自备环保袋,店内不会提供胶袋或纸袋。',
        ]
    },
    {
        title: '如何用米仔币换领货品',
        contents: [
            '会员可按自己需要去拣选合适的货品,并须携往服务处由店员协助换领。',
            '会员须在店员面前打开手机程序上的会员二维码给店员扫描,店员会按会员所拣选货品类别扣减米仔币以作换领。',
            '由于米仔币是按家庭人数发放总数量,会员于换领货品时,均以家庭户口总数内扣减所需的米仔币。',
        ]
    },
    {
        title: '米仔站注意事项',
        contents: [
            '请遵守站内规则及秩序,若店内人数众多,将会实施人流管制措施,届时必须遵从店员指示进入会员换领区换领货品。',
            '换领货品时,必须出示手机程序上的会员二维码,不接受截图。只接受会员本人或所属家庭成员换领,账号切勿外传,否则,惜食堂保留暂停服务的权利。',
            '换领货品前,请小心检查包装及食用期,一经确认换领,不设退换。',
            '购物区物品不能以米仔币换领。',
            '店内已安装闭路电视及录影系统,作保安之用。',
        ]
    },
]

const useDataEn: useDataType = [
    {
        title: 'RB Tokens Distribution',
        contents: [
            'Eligible members will receive 10 RB Tokens weekly, which are distributed every Monday at 00:00am.',
        ]
    },
    {
        title: 'RB Tokens Validity',
        contents: [
            'Weekly RB Tokens are valid from 00: 00am on Monday to 23: 59pm on Sunday in that specific week.',
            'Weekly RB Tokens cannot be accumulated, unused tokens will expire at 23: 59pm on Sunday, and new weekly TB Tokens will be distributed on 00: 00am on Monday.The validity will be stated on the app.',
        ]
    },
    {
        title: 'Display of Available RB Tokens',
        contents: [
            'RB Tokens displayed on the member app shows the combined family token number, i.e.family with 4 members will indicate(10 tokens x 4 =) 40 tokens.',
        ]
    },
    {
        title: 'Rice Boy Station Member Redemption Zone',
        contents: [
            'Tokens needed for each food category will be indicated within the Member Redemption Zone',
            'All products at the Rice Boy Station are donated goods, and items for redemption will be changed from time to time.Available quantity is limited while stocks last.',
            'Quota may be imposed on some items, please redeem according to the rules stated at the shop.',
            'Please bring your own bag, no plastic or paper bags will be provided at the shop.',
        ]
    },
    {
        title: 'How to Use RB Tokens to Redeem Products',
        contents: [
            'Members can select desired products according to needs, and bring to the service counter for store staff’s assistance to redeem. ',
            'Members must open the member QR code from the mobile app for store staff to check and scan, and staff will deduct RB Token according to the chosen product categories. ',
            'As the RB Tokens are distributed according to family size, tokens are deducted from the family account when products are redeemed. ',
        ]
    },
    {
        title: 'Important Notes at Rice Boy Station',
        contents: [
            'Members must follow the rules and order in the shop.If there are too many members in the shop, crowd control measures may be implemented.You must follow the instruction of store staff to enter the Member Redemption Zone to redeem the products. ',
            'To redeem products, member must show the QR code from the mobile app, printed and saved photo of QR code is not accepted.Only the members themselves or their family members are accepted for redemption, the family account cannot be shared with non - members.Otherwise, Food Angel reserves the right to suspend the service.',
            'Check the product packaging and expiry date before redemption.Once products are redeemed, no exchange or return will be accepted.',
            'RB Tokens cannot be used in the Retail Zone.',
            'CCTV and recording devices have been installed at the shop for security purposes. ',
        ]
    },

]
const data = {
    'zh-tw': useDataZhTw,
    'zh-cn': useDataZhCn,
    'en': useDataEn,
}

/**
 * 米仔币使用
 */
export const MiziCoinUse = memo(() => {

    const dispatch = useAppDispatch()
    const config = useAppSelector(state => state.config)

    useEffect(() => {
        (async () => {

        })()
        return () => {
            console.log('MiziCoinUse_卸载')
        }
    }, [])


    console.log('MiziCoinUse')
    return (
        <div
            style={{
                flex: 1,
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 20,
                paddingRight: 20,
                overflowY: 'auto',
                paddingBottom: 100,
            }}
        >
            <img
                src={imgs.micoin}
                style={{
                    height: 40,
                    width: 40,
                    marginTop: 20,
                }}
            />


            {data[config.language].map((item, index) => {
                return (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >

                        {/* {index === 0 && (<LineView />)} */}
                        <LineView style={{ height: index === 0 ? 10 : 25 }} />

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ArrowView />
                            <MyText
                                type='a3'
                                style={{
                                    color: color.mainB,
                                    marginLeft: 5,
                                    marginTop: 1,
                                    fontWeight: 700,
                                }}
                            >{item.title}</MyText>
                        </div>
                        {item.contents.map((content, index) => {

                            return (
                                <div
                                    key={`contents_${index}`}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <LineView />
                                    <TextBoxView>
                                        <MyText
                                            type='a1'
                                            style={{
                                                color: color.mainB,
                                            }}
                                        >{content}</MyText>
                                    </TextBoxView>
                                </div>
                            )

                        })}


                    </div>
                )
            })}



        </div>
    )
},) 


type LineViewProps = {
    style?: React.CSSProperties
}

const LineView = (props: LineViewProps) => {
    return (<div
        style={{
            height: 10,
            width: 2,
            backgroundColor: color.mainB,
            marginLeft: 20 - 1,
            ...props.style || {},
        }}
    />)

}

type ArrowViewProps = {
    style?: React.CSSProperties
}

const ArrowView = (props: ArrowViewProps) => {
    return (
        <div
            style={{
                height: 18,
                width: 18,
                borderRadius: (30) / 2,
                borderColor: color.mainB,
                borderWidth: 2,
                borderStyle: 'solid',
                marginLeft: 10,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                ...props.style || {},
            }}
        >
            <ArrowForwardIosOutlinedIcon
                style={{
                    color: color.mainB,
                    fontSize: 14,
                }}
            />
        </div>
    )
}

type TextBoxViewProps = {
    style?: React.CSSProperties
    children: JSX.Element
}

const TextBoxView = (props: TextBoxViewProps) => {
    return (
        <div
            style={{
                borderRadius: 16 / 2,
                borderColor: color.mainB,
                borderWidth: 2,
                borderStyle: 'solid',
                marginLeft: 6,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                overflow: 'hidden',
                ...props.style || {},
            }}
        >
            <div
                style={{
                    width: 13.5,
                    height: '100%',
                    backgroundColor: color.mainB,
                }}
            />
            <div
                style={{
                    padding: 5,
                    flex: 1,
                }}
            >
                {props.children}
            </div>
        </div>
    )
}

