

const configType = {
    UP_DATA: '[配置]更新数据',
    INIT_DATA: '[配置]初始化',

}

export default {
    configType,//配置
}