import { CircularProgress } from "@mui/material"
import { Button } from "antd-mobile"
import { useEffect, useState } from "react"
import ErrorPage from '../view/ErrorPage/ErrorPage';

type PageBoxType = {
    children?: JSX.Element,
    nav?: JSX.Element,
    onLoadData(): Promise<any>
    style?: React.CSSProperties
}

export const PageBox = (props: PageBoxType) => {
    const [loading, setLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    const loadData = async () => {
        try {
            setLoading(true)
            await props.onLoadData()
            setLoading(false)
            setErrorMessage(undefined)
        } catch (error) {
            setErrorMessage(error.message)
        }
    }

    useEffect(() => {
        (async () => {
            await loadData()
        })()
    }, [])

    if (errorMessage) {
        return (
            <ErrorPage error={{ message: errorMessage }} />
        )
    }

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                flexDirection: 'column',
                ...props.style || {},
            }}
        >
            {props.nav}
            {loading
                ? (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </div>
                )
                : props.children
            }
        </div>
    )
}
