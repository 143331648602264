import { IconButton, Typography } from "@mui/material";
import { color, size } from "../../theme";
import { MyText } from "../../components/MyText";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { animated, useSpring, easings } from '@react-spring/web'

export type HomeCardViewProps = {
    title: string
    type: 'Main0' | 'Main1' | 'Main2' | 'Main3'
    show: boolean
    contentHeight: number
    headerHeight: number
    paddingBotton: number
    children: JSX.Element
    onShow(): void
    style?: React.CSSProperties
    focus?: boolean
}

let top

export const HomeCardView = (props: HomeCardViewProps) => {


    const [styleSpring, api] = useSpring(() => ({
        top: props.style.top,
        transform: `rotate(${!props.show ? `${45 * 6}` : '90'}deg)`,
        config: {
            mass: 1,
            friction: 20,
            tension: 150,
        },
    }))

    if (props?.style?.top !== top) {
        top = props?.style?.top
        api.start({
            top: props.style.top,
            transform: `rotate(${!props.show ? `${45 * 6}` : '90'}deg)`,
        })
    }


    const getBackgroundColor = () => {
        switch (props.type) {
            case 'Main0': {
                return 'white'
            }
            case 'Main1': {
                return color.mainC
            }
            case 'Main2': {
                return color.mainB
            }
            case 'Main3': {
                return color.mainA
            }
        }
    }

    const getTextColor = () => {
        switch (props.type) {
            case 'Main0': {
                return color.mainB
            }
            case 'Main1':
            case 'Main2':
            case 'Main3': {
                return 'white'
            }
        }
    }



    const getBodyViewBackgroundColor = () => {
        switch (props.type) {
            case 'Main1': {
                return color.mainC
            }
            default: return 'white'
        }
    }





    return (
        <animated.div
            style={{
                ...props.style || {},
                top: styleSpring.top,
            }}
        >
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    width: window.innerWidth,
                    flexDirection: 'column',
                }}
            >

                <div
                    style={{
                        height: props.headerHeight,
                        backgroundColor: getBackgroundColor(),
                        borderTopLeftRadius: 22,
                        borderTopRightRadius: 22,


                    }}
                    onClick={() => {
                        props.onShow()
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            paddingLeft: size.changeSize(100),
                            paddingRight: size.changeSize(100),
                        }}
                    >
                        <MyText
                            type={props.focus ? 'a5' : 'a3'}
                            style={{
                                color: getTextColor(),
                                fontWeight: 500,
                                paddingTop: 8,
                            }}
                        >{props.title}</MyText>
                        {props.type === 'Main0' ? <div /> : (

                            <animated.div
                                style={{
                                    transform: styleSpring.transform,
                                }}
                            >
                                <IconButton
                                    size="small"
                                    onClick={() => props.onShow()}
                                >
                                    <ArrowForwardIosOutlinedIcon
                                        style={{
                                            color: 'white',
                                            fontSize: 18,
                                            // transform: `rotate(${!props.show ? `${45 * 6}` : '90'}deg)`,
                                        }}
                                    />
                                </IconButton>
                            </animated.div>



                        )}

                    </div>

                </div>

                <animated.div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: props.contentHeight,
                        backgroundColor: getBodyViewBackgroundColor(),
                        paddingBottom: props.paddingBotton,
                        overflow: 'hidden',
                    }}
                >
                    {props.children}

                </animated.div>
            </div>
        </animated.div>
    )
}

