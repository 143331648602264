import axios from 'axios'
import { ApiConfigType } from "./api-config"
import { toolDispatch } from '../tool'
import { I18n } from 'react-redux-i18n'

export const createHttp = (config: ApiConfigType) => {
    const http = axios.create({
        baseURL: config.url,
        // validateStatus:status=>status===200,
        withCredentials: true, // 默认的
        timeout: 60 * 1000 * 10,

    })
    http.interceptors.request.use(config => {
        return config
    })

    http.interceptors.response.use(response => {
        console.log(`${response.config.method}====>${response.config.url}`, response)
        return response
    }, async (error) => {
        console.log(error)

        switch (error.message) {
            case 'Network Error': {
                window.alert(I18n.t('networkError'))
                return Promise.reject(error.response)
            }
        }
        console.log(`${error.response.config.method}====>${error.response.config.url}[ERROR]`, error.response)
        const status = error.response?.status
        if (status === 403) {
            //退出登录
            
        }
        return Promise.reject(error.response)
    })
    return http
}

