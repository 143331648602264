import { I18n, setLocale } from "react-redux-i18n"
import { useParams, useNavigate, useSearchParams } from "react-router-dom"
import { setConfigData } from "../../actions"
import api, { GetLoale } from "../../api"
import { PostAuthVerifyParameters } from "../../api/api-parameters-type"
import { Nav, PageBox } from "../../components"
import { useAppDispatch, useAppSelector } from "../../store/hooks.ts"


const postAuthVerify = async (params: PostAuthVerifyParameters) => {
    const res = await api.PostAuthVerify(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
}


export const Root = () => {
    const { rootParams } = useParams() as {
        rootParams?: string
    }
    const [searchParams, setSearchParams] = useSearchParams();
    console.log('pathname', window.location.search)
    const familyId = searchParams.get('familyId')
    const hash = searchParams.get('hash')
    const lang = searchParams.get('lang') as GetLoale || 'zh-tw'
    const config = useAppSelector(state => state.config)
    let navigate = useNavigate();
    console.log('familyId', familyId)
    const dispatch = useAppDispatch()
    return (
        <>
            <PageBox
                onLoadData={async () => {
                    const auth = await postAuthVerify({ familyId: familyId, hash })
                    console.log('auth', auth)
                    await dispatch(setConfigData({
                        ...config,
                        language: auth.locale,
                    }))
                    navigate("/home");
                }}

            />
        </>
    )
}