export default {
  "HomeCardView__pageA_title": "你的米仔币",
  "HomeCardView__pageB_title": "兑换记录",
  "HomeCardView__pageC_title": "米仔站資訊",
  "HomeCardView__pageD_title": "米仔币使用",

  "MiziCoinInfo__sponsor": "赞助机构",
  "MiziCoinRecord__listText": "于 %{moment} 成功扣减 %{totalCoin} 米仔币",
  "MiziCoin__accepted": "于 %{moment} 接收 %{totalCoin} 米仔币",
  "MiziCoin__expiredAt": "到期日为",

  "loadingContentText": "加载中",

  "loadingErrorButton": "重新加载",
  "loadingErrorInfo": "发生错误, 请稍后重试",

  "myListEmptyComponent__title": "暂无数据",

  "networkError": "你的网络异常，请稍后重试。",

  "pageBoxErrorButton": "重新加载",
  "pageBoxErrorText": "加载错误，请刷新界面。"
}