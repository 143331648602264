import { useEffect, useState } from "react";
import { I18n, setLocale } from "react-redux-i18n";
import { PageBox, Nav } from "../../components";
import { color, size } from "../../theme";
import { useAppDispatch, useAppSelector } from "../../store/hooks.ts";
import { HomeCardView, HomeCardViewProps } from "./HomeCardView";
import { MiziCoinUse } from "../MiziCoinUse/MiziCoinUse";
import { MiziCoin } from "../MiziCoin/MiziCoin";
import { MiziCoinInfo } from "../MiziCoinInfo/MiziCoinInfo";
import { MiziCoinRecord } from "../MiziCoinRecord/MiziCoinRecord";
import api, { GetRedemptionRecordRes, GetUserCoinDateRes } from "../../api";
import { GetRedemptionRecordListParameters, GetUserCoinDateListParameters, PostAuthVerifyParameters } from "../../api/api-parameters-type";




const getRedemptionRecordList = async (params: GetRedemptionRecordListParameters) => {
    const res = await api.GetRedemptionRecordList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
}

const getUserCoinDateList = async (params: GetUserCoinDateListParameters) => {
    const res = await api.GetUserCoinDateList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
}


export const Home = () => {

    const dispatch = useAppDispatch()
    const config = useAppSelector(state => state.config)
    const i18n = useAppSelector(state => state.i18n)

    const [showIndex, setShowIndex] = useState(0)

    const [recordList, setRecordList] = useState<GetRedemptionRecordRes[]>([])
    const [coinDateList, setCoinDateList] = useState<GetUserCoinDateRes[]>([])

    useEffect(() => {
        (async () => {

        })()
    }, [])

    const listData: {
        type: HomeCardViewProps['type']
        title: HomeCardViewProps['title']
        children: HomeCardViewProps['children']
    }[] = [
            {
                type: 'Main0',
                title: I18n.t('HomeCardView__pageA_title'),
                children: <MiziCoin userCoinList={coinDateList} />
            },
            {
                type: 'Main1',
                title: I18n.t('HomeCardView__pageB_title'),
                children: <MiziCoinRecord recordList={recordList} />
            },
            {
                type: 'Main2',
                title: I18n.t('HomeCardView__pageC_title'),
                children: <MiziCoinInfo />
            },
            {
                type: 'Main3',
                title: I18n.t('HomeCardView__pageD_title'),
                children: <MiziCoinUse />
            },
        ]



    return (
        <PageBox
            onLoadData={async () => {
                const record = await getRedemptionRecordList({ limit: 10000 })
                const coinDateList = await getUserCoinDateList({ limit: 10000 })
                setRecordList(record.data)
                setCoinDateList(coinDateList.data)
            }}
            style={{
                backgroundColor: color.mainA
            }}
        >
            <div
                // className="home-card-container"
                style={{
                    flex: 1,
                    position: 'relative',
                }}
            >

                {listData.map((item, index) => {
                    const show = index === 0
                        ? true
                        : showIndex >= index
                            ? true
                            : false



                    let headerHeight = size.changeSize(200)
                    let paddingBotton = (listData.length - index - 1) * headerHeight
                    paddingBotton = paddingBotton - (index === (listData.length - 1) ? 0 : (listData.length - 1 - index) * 20)
                    let contentHeight = window.innerHeight - paddingBotton - (headerHeight * (index + 1))
                    contentHeight = contentHeight + ((index) * 25)

                    return (
                        <HomeCardView
                            style={{
                                position: 'absolute',
                                zIndex: index + 100,
                                left: 0,
                                top: show ? headerHeight * index * .6 : window.innerHeight - headerHeight - paddingBotton,
                                right: 0,
                            }}
                            key={index}
                            title={item.title}
                            type={item.type}
                            show={show}
                            contentHeight={contentHeight}
                            headerHeight={headerHeight}
                            paddingBotton={paddingBotton}
                            children={item.children}
                            onShow={() => {
                                setShowIndex(index)
                            }}
                            focus={showIndex === index}
                        />
                    )
                })}
            </div>



        </PageBox >
    )
}