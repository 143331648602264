import './App.css';
import { ConfigProvider } from 'antd-mobile'
import zhCN from 'antd-mobile/es/locales/zh-CN'
import zhHK from 'antd-mobile/es/locales/zh-HK'
import enUS from 'antd-mobile/es/locales/en-US'
import { useAppSelector, useAppDispatch } from './store/hooks.ts';
import { createBrowserRouter, createHashRouter, RouterProvider, useRouteError } from 'react-router-dom';
import ErrorPage from './view/ErrorPage/ErrorPage';
import { Root } from './view/Root/Root';
import { Home } from './view/Home/Home';


const ErrorPageElement = () => {
  const error: any = useRouteError();

  return <ErrorPage error={error} />;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPageElement />,
  },
  {
    path: "/home",
    element: <Home />,
    errorElement: <ErrorPageElement />,
  },
]);


const App = () => {
  const config = useAppSelector(state => state.config)

  const getLang = () => {
    switch (config.language) {
      case 'en': {
        return enUS
      }
      case 'zh-cn': {
        return zhCN
      }
      case 'zh-tw': {
        return zhHK
      }
      default: return zhHK
    }
  }
  return (
    <>
      <ConfigProvider locale={getLang()}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </>
  );
}


export default App


if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.info = () => { }
  console.warn = () => { }
}