import { memo, useEffect, useState } from "react";
import { PageBox, Nav } from "../../components";
import { color, size } from "../../theme";
import { useAppDispatch, useAppSelector } from "../../store/hooks.ts";
import { MyText } from "../../components/MyText";
import { GetUserCoinDateRes } from "../../api";
import *as imgs from "../../imgs";
import { I18n } from "react-redux-i18n";
import moment from "moment";


export type MiziCoinProps = {
    userCoinList: GetUserCoinDateRes[]
}

/**
 * 米仔币
 */
export const MiziCoin = memo((props: MiziCoinProps) => {

    const dispatch = useAppDispatch()
    const config = useAppSelector(state => state.config)

    useEffect(() => {
        (async () => {

        })()
    }, [])

    const totalCoin = props.userCoinList.reduce((previousValue, currentValue: GetUserCoinDateRes) => {
        return previousValue + currentValue.amount
    }, 0)

    const userCoinList = props.userCoinList.filter(coin => coin.amount > 0)

    const userCoinFirst = props.userCoinList[0]

    return (
        <div
            style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'scroll',
            }}
        >
            <div
                style={{
                    flex: 1,
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 30,
                    marginRight: 30,
                    marginBottom: 40,

                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 10,
                    }}
                >
                    <MyText
                        fontFamily='BdCn'
                        style={{
                            fontSize: 70,
                            color: color.mainA,
                        }}
                    >{`${totalCoin || 0}`}</MyText>
                    <img
                        src={imgs.micoin}
                        style={{
                            height: 70,
                            marginBottom: 20,
                            marginLeft: 10,
                        }}
                    />
                </div>
                {userCoinFirst && (
                    <MyText
                        type='a1'
                        style={{
                            fontWeight: '400',
                            color: color.mainB,
                            marginTop: 15,
                        }}
                    >{I18n.t('MiziCoin__accepted', { moment: moment(userCoinFirst.createdAt).format('DD-MM-YYYY HH:mm'), totalCoin: userCoinFirst.originalAmount })}</MyText>
                )}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 15,
                    }}
                >
                    <div
                        style={{
                            height: 1,
                            backgroundColor: color.mainB,
                        }}
                    />
                    {
                        userCoinList.map((userCoin, index) => {
                            return (
                                <div

                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: 15,
                                    }}

                                    key={index}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <MyText
                                            type='a1'
                                            style={{
                                                color: color.mainB,
                                                fontWeight: '400',
                                            }}
                                        >{I18n.t('MiziCoin__expiredAt')}</MyText>
                                        <MyText
                                            type='a1'
                                            style={{
                                                color: color.mainB,
                                                fontWeight: '400',
                                            }}
                                        >{`${moment(userCoin.expiredAt).format('DD-MM-YYYY HH:mm')}`}</MyText>
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <MyText
                                            fontFamily='BdCn'
                                            style={{
                                                fontSize: 25,
                                                color: color.mainA,
                                            }}
                                        >{`${userCoin.amount}`}</MyText>
                                        <img
                                            src={imgs.micoin}
                                            style={{
                                                height: 25,
                                                marginBottom: 6,
                                                marginLeft: 5,
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div
                        style={{
                            height: 1,
                            backgroundColor: color.mainB,
                            marginTop: 15,

                        }}
                    />
                </div>
            </div>
        </div>

    )
})
