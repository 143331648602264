



const parse = (text: any) => {

    try {
        return JSON.parse(text)
    } catch (error) {
        return false
    }

}

class Config {
    REACT_APP_API = String(process.env['REACT_APP_API'])
    isEnableTestAPIData = parse(String(process.env['REACT_APP_IS_ENABLE_TEST_API_DATA']))

}

const config = new Config()
export default config


