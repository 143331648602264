import Type from './action-type';
import { MyThunkDispatch } from '.';
import { setLocale } from 'react-redux-i18n';
import moment from 'moment'
import 'moment/locale/zh-cn'
import 'moment/locale/zh-tw'
import 'moment/locale/en-au'
import { GetLoale } from '../api';

const updataActon = (data: any) => {
    return {
        type: Type.configType.UP_DATA, ...data
    }
}

const initDataAction = (): any => {
    return {
        type: Type.configType.INIT_DATA
    }
}


export interface ActionConfigRes {
    kioskInventories: string[]
    areaIds: string[]
    language: GetLoale
    selectAreaId?: string
}



const setMomentLocale = (language: ActionConfigRes['language']) => {
    switch (language) {
        case 'en': {
            moment.locale('en-au')
        }
            break
        default: {
            moment.locale(language)
        }
    }
}


export const setConfigData = (data: ActionConfigRes) => {
    return async (dispatch: MyThunkDispatch) => {



        setMomentLocale(data.language)
        dispatch(setLocale(data.language))


        dispatch(updataActon(data))
    }
}











