export default {
  "HomeCardView__pageA_title": "Your RB Token",
  "HomeCardView__pageB_title": "Redeem Record",
  "HomeCardView__pageC_title": "About Rice Boy Station",
  "HomeCardView__pageD_title": "How to Use RB Tokens",

  "MiziCoinInfo__sponsor": "Sponsor",
  "MiziCoinRecord__listText": "On %{moment} deducted %{totalCoin} RB Tokens",
  "MiziCoin__accepted": "On %{moment} received %{totalCoin} RB Tokens",
  "MiziCoin__expiredAt": "Last day of use",

  "loadingContentText": "Loading",

  "loadingErrorButton": "Reload",
  "loadingErrorInfo": "Error occurred. Please reload later",

  "myListEmptyComponent__title": "No data yet",

  "networkError": "Your network is abnormal, please try again later.",

  "pageBoxErrorButton": "Reload",
  "pageBoxErrorText": "Loading error, please refresh the interface."
}