export default {
  "HomeCardView__pageA_title": "你的米仔幣",
  "HomeCardView__pageB_title": "兌換記錄",
  "HomeCardView__pageC_title": "米仔站資訊",
  "HomeCardView__pageD_title": "米仔幣使用",

  "MiziCoinInfo__sponsor": "贊助機構",
  "MiziCoinRecord__listText": "於 %{moment} 成功扣減 %{totalCoin} 米仔幣",
  "MiziCoin__accepted": "於 %{moment} 接收 %{totalCoin} 米仔幣",
  "MiziCoin__expiredAt": "到期日為",

  "loadingContentText": "加載中",

  "loadingErrorButton": "重新加載",
  "loadingErrorInfo": "發生錯誤, 請稍後重試",

  "myListEmptyComponent__title": "暫無數據",

  "networkError": "你的網絡異常，請稍後重試。",

  "pageBoxErrorButton": "重新加載",
  "pageBoxErrorText": "加載錯誤，請刷新界面。"
}