import { memo, useEffect, useState } from "react";
import { PageBox, Nav } from "../../components";
import { color, size } from "../../theme";
import { useAppDispatch, useAppSelector } from "../../store/hooks.ts";
import { MyText } from "../../components/MyText";
import { GetRedemptionRecordRes } from "../../api";
import { I18n } from "react-redux-i18n";
import moment from "moment";


export type MiziCoinRecordProps = {
    recordList: GetRedemptionRecordRes[]
}

/**
 * 兑换记录
 */
export const MiziCoinRecord = memo((props: MiziCoinRecordProps) => {

    const dispatch = useAppDispatch()
    const config = useAppSelector(state => state.config)

    useEffect(() => {
        (async () => {

        })()
    }, [])
    console.log('MiziCoinRecord')

    return (
        <>
            <div
                style={{
                    backgroundColor: 'white',
                    height: 1,
                    marginLeft: 25,
                    marginRight: 25,
                }}
            />
            <div
                style={{
                    flex: 1,
                    backgroundColor: color.mainC,
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 20,
                    paddingLeft: 25,
                    paddingRight: 25,
                    paddingBottom: 40,
                    overflow: 'scroll',

                }}
            >

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >

                    {props.recordList.map((record, index) => {
                        return (
                            <MyText
                                key={index}
                                type='a1'
                                style={{
                                    marginTop: 14,
                                    color: 'white',

                                }}
                            >
                                {
                                    I18n.t('MiziCoinRecord__listText',
                                        {
                                            moment: `${moment(record.createdAt).format('DD-MM-YYYY HH:mm')}`,
                                            totalCoin: `${Math.abs(record.totalCoin)}`
                                        })
                                }
                            </MyText>
                        )
                    })}
                </div>

            </div>
        </>

    )
}) 
