
import { ApiConfig, ApiConfigType } from "./api-config"
import { AxiosInstance } from 'axios'
import { createHttp } from './http';
import *as result from "./api-result-type";
import *as Parameters from "./api-parameters-type";
import *as res from "./api-res-type";
import config from "../config";
import *as apiResultDataTest from "./api-result-data-test";

export class Api {

  config: ApiConfigType
  http: AxiosInstance

  constructor (config: ApiConfigType = ApiConfig) {
    this.config = config
    this.http = createHttp(this.config)
  }



  /**
   * 登录
   */
  async PostAuthVerify(params: Parameters.PostAuthVerifyParameters): Promise<result.PostAuthVerifyResult> {
    try {
      if (config.isEnableTestAPIData) {
        return await apiResultDataTest.PostAuthVerifyResultData()
      }

      const response = await this.http.post('/app/auth/verify', params)
      const data: res.PostAuthVerifyRes = response.data.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data || '' }
    }
  }

  /**
   * 取货记录 
   */
  async GetRedemptionRecordList(params: Parameters.GetRedemptionRecordListParameters): Promise<result.GetRedemptionRecordListResult> {
    try {
      if (config.isEnableTestAPIData) {
        return await apiResultDataTest.GetRedemptionRecordListResultData()
      }
      const response = await this.http.get(`/app/redemption-record`, { params })
      const data: res.GetRedemptionRecordListRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data || '' }
    }
  }


  /**
   * 用户积分
   */
  async GetUserCoinDateList(params: Parameters.GetUserCoinDateListParameters): Promise<result.GetUserCoinDateListResult> {
    try {
      if (config.isEnableTestAPIData) {
        return await apiResultDataTest.GetUserCoinDateListResultData()
      }
      const response = await this.http.get(`/app/user-coin-date`, { params })
      const data: res.GetUserCoinDateListRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data || '' }
    }
  }


  /**
   * 店铺
   */
  async GetShopList(params: Parameters.GetShopListParameters): Promise<result.GetShopListResult> {
    try {
      if (config.isEnableTestAPIData) {
        return await apiResultDataTest.GetShopListResultData()
      }
      const response = await this.http.get(`/app/shop`, { params })
      const data: res.GetShopListRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data || '' }
    }
  }


  /**
   * 地区
   */
  async GetRegionList(params: Parameters.GetRegionListParameters): Promise<result.GetRegionListResult> {
    try {
      if (config.isEnableTestAPIData) {
        return await apiResultDataTest.GetRegionListResultData()
      }
      const response = await this.http.get(`/app/region`, { params })
      const data: res.GetRegionListRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data || '' }
    }
  }


}


