const primaryTheme:any = require('./custom-theme.json')
const secondaryTheme:any = require('./custom-theme-secondary.json')

export const color = {
    primary_0: primaryTheme['color-primary-500'],
    primary_1: primaryTheme['color-primary-600'],
    primary_2: primaryTheme['color-primary-700'],
    primary_3: primaryTheme['color-primary-800'],
    primary_4: primaryTheme['color-primary-900'],

    secondary_1_0: secondaryTheme['color-primary-400'],
    secondary_1_1: secondaryTheme['color-primary-600'],
    secondary_1_2: secondaryTheme['color-primary-700'],
    secondary_1_3: secondaryTheme['color-primary-800'],
    secondary_1_4: secondaryTheme['color-primary-900'],

    secondary_2_0: '#F59329',
    secondary_2_1: '#B25D00',
    secondary_2_2: '#CF6C00',
    secondary_2_3: '#BD7321',
    secondary_2_4: '#9F611D',

    black_0:'rgba(0,0,0,1)',
    black_1:'rgba(0,0,0,0.8)',
    black_2:'rgba(0,0,0,0.5)',
    black_3:'rgba(0,0,0,0.2)',
    black_4:'rgba(0,0,0,0.05)',

    mainA:'#86BD25',
    mainB:'#263263',
    mainC:'#D33A6D',
    
}   