import { useEffect, useState } from "react"
type MyTextType = {
    children: string
    type?: 'a1' | 'a2' | 'a3' | 'a4' | 'a5' | 'a6'
    style?: React.CSSProperties
    fontFamily?: 'Bd' |
    'BdCn' |
    'Black'
}

const setDefaultTextStyle = (props: MyTextType): React.CSSProperties => {

    let fontSize = 14
    let fontFamily = props.fontFamily || 'Bd'
    let scale = 1.2
    switch (props.type) {
        case 'a1': {
            fontSize = 11 * scale
        }
            break
        case 'a2': {
            fontSize = 12 * scale
        }
            break
        case 'a3': {
            fontSize = 13 * scale
        }
            break
        case 'a4': {
            fontSize = 15 * scale
        }
            break
        case 'a5': {
            fontSize = 16 * scale
        }
            break
        case 'a6': {
            fontSize = 20 * scale
        }
            break
    }

    return {
        padding: 0,
        margin: 0,
        fontSize,
        fontFamily,
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
    }
}

export const MyText = (props: MyTextType) => {

    useEffect(() => {
        (async () => {

        })()
    }, [])


    return (
        <span
            style={{
                ...setDefaultTextStyle(props),
                ...props.style || {}
            }}
        >{props.children}</span>
    )
}
