/**
 * Created by gaocai on 16/9/19.
 */

import { combineReducers } from 'redux'
import Type from '../actions/action-type';
import { i18nReducer } from 'react-redux-i18n';
import { ActionConfigRes } from '../actions';



const configState = (): ActionConfigRes => {
    return {
        kioskInventories: [],
        language: 'zh-tw',
        areaIds: [],
    }
}
const config = (state = configState(), action: { type: any; }) => {
    switch (action.type) {
        case Type.configType.UP_DATA:
            return {
                ...state,
                ...action,
            };
        case Type.configType.INIT_DATA:
            return {
                ...configState()
            };
        default:
            return state;
    }
}



export default combineReducers({
    i18n: i18nReducer,
    config,
});